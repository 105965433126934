<template>
   <div>
      <Slider />
      <CareerList />
   </div>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';

export default {
    name: 'Careers',
     components: {
      Slider: () => import('./slider'),
      CareerList: () => import('./careerList.vue'),

    },
    mixins: [coreMixin],

}
</script>

<style>

</style>